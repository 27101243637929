export const ID_TYPES = [
  { label: 'DNI', value: 'DNI' },
  { label: 'LE', value: 'LE' },
  { label: 'PASAPORTE', value: 'PASAPORTE' },
  { label: 'CI', value: 'CI' },
  { label: 'CURP', value: 'CURP' },
  { label: 'DPI/DUI', value: 'DPI/DUI' },
  { label: "Carte nationale d'identité", value: "Carte nationale d'identité" },
];

export const isArcos = Number(process.env.REACT_APP_ID_ENTITY) === 8399;
export const isBisblick = Number(process.env.REACT_APP_ID_ENTITY) === 3885;
export const isEmpujar = Number(process.env.REACT_APP_ID_ENTITY) === 633;
export const isOsmia = Number(process.env.REACT_APP_ID_ENTITY) === 4755;
export const isAquiEstoy = Number(process.env.REACT_APP_ID_ENTITY) === 6341;
export const isGlobant = Number(process.env.REACT_APP_ID_ENTITY) === 71;
export const isAlmaCivica = Number(process.env.REACT_APP_ID_ENTITY) === 8601;

export const isBaNorte_ = Number(process.env.REACT_APP_ID_ENTITY) === 6318;


export const isCinia = Number(process.env.REACT_APP_ID_ENTITY) === 2778;



export const AUTO_REGISTER = [isArcos, isAlmaCivica, isAquiEstoy];


export const handleFocus = (e) => {
  if (isBaNorte_) {
    e.target.style.borderColor = '#2DAAE1';
    e.target.style.outline = 'none';
    e.target.style.boxShadow = 'none';
  }
};

export const handleBlur = (e) => {
  if (isBaNorte_) {
    e.target.style.borderColor = '';
    e.target.style.boxShadow = '';
  }
};

export const getColors = (color) => {
  switch (color) {
    case 'success':
      return { color: '#fff', backgroundColor: '#34bfa3' };
    case 'secondary':
      return { color:  '#000', backgroundColor: '#ebedf2' };
    case 'brand':
      return { color: '#fff', backgroundColor: '#716aca' };
    case 'warning':
      return { color: '#fff', backgroundColor: '#ffb822' };
    case 'info':
      return { color: '#fff', backgroundColor: '#36a3f7' };
    case 'primary':
      return { color: '#fff', backgroundColor: '#5867dd' };
    case 'accent':
      return { color: '#fff', backgroundColor: '#00c5dc' };
    case 'metal':
      return { color: '#fff', backgroundColor: '#c4c5d6' };
    case 'danger':
      return { color: '#fff', backgroundColor: '#f4516c' };
    case 'orange':
      return { color: '#fff', backgroundColor: '#F2A900' };
    case 'yellow':
      return { color:  '#000', backgroundColor: '#FFF000' };
    case 'red':
      return { color: '#fff', backgroundColor: '#E4002B' };
    case 'purple':
      return { color: '#fff', backgroundColor: '#6B3077' };
    case 'gray':
      return { color: '#fff', backgroundColor: '#5E514D' };
    case 'green':
      return { color: '#fff', backgroundColor: '#128935' };
    default:
      return { color: '#fff', backgroundColor: '#34bfa3' };
  }
};

